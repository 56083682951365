import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import {
  Form,
  Navbar,
  Placeholder,
  ProgressBar,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { FiDownload, FiLogOut } from "react-icons/fi";
import { toast } from "react-toastify";
import { CLIENT_ID, USER_ID } from "../../common/key";
import { icons } from "../../images/icons";
import {
  downloadReport,
  getReportList,
  uploadFile,
} from "../../services/apis/uploadFile";
import "./uploadReport.scss";

function UploadReport({ setLoggedIn }) {
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [emptyFileName, setEmptyFileName] = useState(false);
  const [reportList, setReportList] = useState([]);
  const [reportType, setReportType] = useState("ORDERS");
  const [reportDate, setReportDate] = useState(null);
  const [emptyErrorDate, setEmptyErrorDate] = useState(false);
  const [emptyErrorFile, setEmptyErrorFile] = useState(false);
  const [reportListLoading, setReportListLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [downloadId, setDownloadId] = useState(null);

  const reportTypeOptions = [
    {
      value: "ORDERS",
      label: "Orders report",
    },
    {
      value: "FINANCE",
      label: "Finance report",
    },
  ];

  const getReportName = (item) => {
    const parts = item.split("/");
    const lastPart = parts.pop();
    return lastPart;
  };

  const handleDownloadReport = (reportUUID, reportName) => {
    setDownloadId(reportUUID);
    downloadReport(reportUUID)
      .then((res) => {
        console.log("res", res);
        // Create a blob from the response data
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Create a download link
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", reportName);
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Cleanup
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        console.log("error in downloadReport", err);
        if (err?.response?.status === 401) {
          toast.error("Session expired. Please login again", {
            position: toast.POSITION.TOP_RIGHT,
          });
          sessionStorage.clear();
          setLoggedIn(false);
        }
      })
      .finally(() => {
        setDownloadId(null);
      });
  };

  const handleOptionChange = (e) => {
    setReportType(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let check = true;
    if (!file) {
      setEmptyErrorFile(true);
      check = false;
    }
    if (!reportDate) {
      setEmptyErrorDate(true);
      check = false;
    }
    if (!fileName || fileName.trim() === "") {
      setEmptyFileName(true);
      check = false;
    }
    if (!check) return;

    event.target.disabled = true;
    const userId = sessionStorage.getItem(USER_ID);
    const clientId = sessionStorage.getItem(CLIENT_ID);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("reportType", reportType);
    formData.append("reportDate", reportDate);
    formData.append("userId", userId);
    formData.append("clientId", clientId);
    formData.append("name", fileName);

    const response = await uploadFile(formData, setUploadProgress);
    if (response?.status) {
      toast.success("Report uploaded successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error("Failed to upload the report. Please try again later", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    // reload the page after 3 seconds
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const handleGetReports = () => {
    setReportListLoading(true);
    getReportList()
      .then((res) => {
        setReportList(res?.data?.data ?? []);
      })
      .catch((err) => {
        console.log("error in getReportList", err);
        if (err?.response?.status === 401) {
          toast.error("Session expired. Please login again", {
            position: toast.POSITION.TOP_RIGHT,
          });
          sessionStorage.clear();
          setLoggedIn(false);
        }
      })
      .finally(() => {
        setReportListLoading(false);
      });
  };

  useEffect(() => {
    handleGetReports();
  }, []);

  return (
    <div className="main-container">
      <Navbar className="header-navbar">
        <a
          href="https://voosh.ai/"
          target="_blank"
          rel="noreferrer"
          className="logo-link"
        >
          <img
            className="voosh-logo"
            src={icons.vooshLogoSquare}
            alt="voosh-logo"
          />
        </a>
        <button
          className="logout"
          onClick={() => {
            sessionStorage.clear();
            setLoggedIn(false);
          }}
        >
          <span>Logout</span>
          <FiLogOut size={15} />
        </button>
      </Navbar>
      <div className="form-container">
        <div className="title">Upload your Order/Finance report</div>
        <div className="body">
          <Form noValidate data-bs-theme="light" onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                Report type <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <div>
                {reportTypeOptions.map((option, index) => (
                  <Form.Check
                    key={index}
                    label={option.label}
                    name="report-type"
                    type={"radio"}
                    id={option.value}
                    inline
                    value={option.value}
                    checked={reportType === option.value}
                    onChange={handleOptionChange}
                  />
                ))}
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                {" "}
                Report name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                className="date-picker"
                value={fileName}
                onChange={(e) => {
                  setFileName(e.target.value);
                  setEmptyFileName(false);
                }}
              />
              {emptyFileName && (
                <Form.Control.Feedback type="invalid">
                  {" "}
                  Please enter file name
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                {" "}
                Report date <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="date"
                className="date-picker"
                value={reportDate}
                onChange={(e) => {
                  setReportDate(e.target.value);
                  setEmptyErrorDate(false);
                }}
              />
              {emptyErrorDate && (
                <Form.Control.Feedback type="invalid">
                  {" "}
                  Please select date
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                Upload report <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="file"
                style={{ width: "fit-content" }}
                accept=".xlsx,.csv,.json"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                  setEmptyErrorFile(false);
                }}
              />
              {emptyErrorFile && (
                <Form.Control.Feedback type="invalid">
                  {" "}
                  Please select file
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Row className="upload-handler-container">
              <button
                type="submit"
                className="submit-report"
                disabled={
                  !fileName ||
                  !file ||
                  !reportType ||
                  !reportDate ||
                  uploadProgress > 0
                }
              >
                Upload Report
              </button>
              {uploadProgress > 0 && (
                <ProgressBar
                  className="custom-bar"
                  animated
                  now={uploadProgress}
                  label={`${uploadProgress}%`}
                />
              )}
            </Row>
          </Form>
        </div>
      </div>
      <div className="history-container">
        <div className="title">Report history</div>
        <div className="body">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th className="sr">#</th>
                <th>Report name </th>
                <th>Report type</th>
                <th>Upload date</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody>
              {reportListLoading &&
                Array(10)
                  .fill(0)
                  .map((item, index) => (
                    <tr key={index}>
                      <td>
                        <Placeholder animation="glow">
                          <Placeholder xs={6} />
                        </Placeholder>
                      </td>
                      <td>
                        <Placeholder animation="glow">
                          <Placeholder xs={6} />
                        </Placeholder>
                      </td>
                      <td>
                        <Placeholder animation="glow">
                          <Placeholder xs={6} />
                        </Placeholder>
                      </td>
                      <td>
                        <Placeholder animation="glow">
                          <Placeholder xs={6} />
                        </Placeholder>
                      </td>
                      <td>
                        <Placeholder animation="glow">
                          <Placeholder xs={6} />
                        </Placeholder>
                      </td>
                    </tr>
                  ))}
              {!reportListLoading &&
                reportList.length > 0 &&
                reportList.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      {item?.name ? item.name : getReportName(item?.s3Link)}
                    </td>
                    <td>{item?.reportType}</td>
                    <td>
                      {moment(item?.createdAt, "YYYY-MM-DD").format(
                        "MMM DD,YYYY"
                      )}
                    </td>
                    <td className="download">
                      <button
                        onClick={() => {
                          const tmp = getReportName(item?.s3Link);
                          handleDownloadReport(item?.reportUUID, tmp);
                        }}
                      >
                        <FiDownload size={20} />
                      </button>
                      {downloadId === item?.reportUUID && (
                        <div className="download-progress">
                          {" "}
                          <Spinner animation="border" role="status" size="sm" />
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              {!reportListLoading && reportList.length === 0 && (
                <tr>
                  <td colSpan={4} className="no-data">
                    No reports to show!
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default UploadReport;
