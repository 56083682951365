import React, { useState } from "react";
import "./login.scss";

import { Container, Form, Spinner } from "react-bootstrap";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { BiRefresh } from "react-icons/bi";
import { toast } from "react-toastify";
import { ACCESS_TOKEN, CLIENT_ID, USER_ID } from "../../common/key";
import { icons } from "../../images/icons";
import { signIn } from "../../services/apis/auth";

function Login({ setLoggedIn }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberme, setRememberme] = useState(true);
  const [currentScreen, setCurrentScreen] = useState("LOGIN_SCREEN");
  const [loading, setLoading] = useState(false);

  const handleLogin = () => {
    if (!email || !password) {
      toast.error("Please enter email and password");
      return;
    }
    setLoading(true);
    signIn({ email, password })
      .then((res) => {
        sessionStorage.setItem(ACCESS_TOKEN, res.data.data.token);
        sessionStorage.setItem(USER_ID, res.data.data.id);
        sessionStorage.setItem(CLIENT_ID, res.data.data.clientId);
        setLoggedIn(true);
      })
      .catch((err) => {
        setCurrentScreen("ERROR_SCREEN");
        console.log("error in signIn", err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="login-main">
      <img
        src={icons.vooshLogoSquare}
        className="voosh-logo"
        alt="voosh-logo"
      />
      {currentScreen === "LOGIN_SCREEN" && (
        <div className="login-container">
          <div className="login_title_container">
            <div className="login_title">Login</div>
          </div>

          <Container className="login-form-container">
            <Form
              className="login-form-main"
              onSubmit={(e) => {
                e.preventDefault();
                handleLogin();
              }}
            >
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Email<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="mail@website.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form_input"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>
                  Password<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <div className="row-style">
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Min. 8 characters"
                    value={password}
                    minLength={8}
                    onChange={(e) => setPassword(e.target.value)}
                    className="form_input"
                  />
                  {showPassword ? (
                    <AiFillEyeInvisible
                      size={20}
                      onClick={() => setShowPassword(false)}
                      className="password-eye-button"
                    />
                  ) : (
                    <AiFillEye
                      size={20}
                      onClick={() => setShowPassword(true)}
                      className="password-eye-button"
                    />
                  )}
                </div>
              </Form.Group>

              <div className="checkbox-container">
                <Form.Check
                  id="rememberme"
                  type="checkbox"
                  checked={rememberme}
                  label="Remember me"
                  onChange={(e) => setRememberme(e.target.checked)}
                />
              </div>

              <button className="login_button" type="submit" disabled={loading}>
                <span className="login_button_text">Login</span>
                {loading && <Spinner animation="border" size="sm" />}
              </button>
            </Form>
          </Container>
        </div>
      )}
      {currentScreen === "ERROR_SCREEN" && (
        <div className="login_error_container">
          <img
            src={icons.login_error}
            className="login_error_img"
            alt="login_error_img"
          />
          <div className="login_error_text">
            Unable to login/sign-up please try again
          </div>
          <button
            className="login_error_button"
            onClick={() => {
              setCurrentScreen("LOGIN_SCREEN");
              setEmail("");
              setPassword("");
            }}
          >
            <BiRefresh color="#ffffff" />
            <span>Refresh</span>
          </button>
        </div>
      )}
    </div>
  );
}

export default Login;
