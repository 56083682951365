import axios from "axios";
import { ACCESS_TOKEN, CLIENT_ID, USER_ID } from "../../common/key";
import { URLS } from "../urls";

export const uploadFile = async (payload, setProgress) => {
  const access_token = sessionStorage.getItem(ACCESS_TOKEN);
  return axios.request({
    method: "post",
    url: URLS.uploadFile,
    data: payload,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + access_token,
    },
    onUploadProgress: (progressEvent) => {
      setProgress(
        parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
      );
    },
  });
};

export const getReportList = () => {
  const userId = sessionStorage.getItem(USER_ID);
  const clientId = sessionStorage.getItem(CLIENT_ID);
  const access_token = sessionStorage.getItem(ACCESS_TOKEN);
  let data = JSON.stringify({
    userId,
    clientId,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: URLS.getReportList,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    },
    data: data,
  };

  return axios.request(config);
};

export const downloadReport = (reportUUID) => {
  const userId = sessionStorage.getItem(USER_ID);
  const clientId = sessionStorage.getItem(CLIENT_ID);
  const access_token = sessionStorage.getItem(ACCESS_TOKEN);
  let data = JSON.stringify({
    userId,
    clientId,
    reportUUID,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: URLS.downloadReport,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    },
    data: data,
    responseType: "blob",
  };

  return axios.request(config);
};
