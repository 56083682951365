// prod
const BASE_URL = "https://micro-service.voosh.ai";

// localhost
// const BASE_URL = "http://localhost:8000";

export const URLS = {
  uploadFile: BASE_URL + "/financial-reconciliation/upload-report",
  getReportList: BASE_URL + "/financial-reconciliation/get-reports",
  signIn: BASE_URL + "/sign-in",
  downloadReport: BASE_URL + "/financial-reconciliation/download-report",
};
