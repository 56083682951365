import axios from "axios";
import { URLS } from "../urls";

export const signIn = ({ email, password }) => {
  let data = JSON.stringify({
    email,
    password,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: URLS.signIn,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios.request(config);
};
