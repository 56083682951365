import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { ACCESS_TOKEN } from "./common/key";
import UploadReport from "./pages/uploadReport/uploadReport";

import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import Login from "./pages/login/login";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);

  const checkLoggedIn = () => {
    const token = sessionStorage.getItem(ACCESS_TOKEN);
    if (token) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  };

  useEffect(() => {
    checkLoggedIn();
  }, []);

  return (
    <div className="app-container">
      {loggedIn ? (
        <UploadReport setLoggedIn={setLoggedIn} />
      ) : (
        <Login setLoggedIn={setLoggedIn} />
      )}
      <ToastContainer autoClose={2000} />
    </div>
  );
}

export default App;
